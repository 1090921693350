/** @jsx jsx */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/core'
import striptags from 'striptags'

import { Flex, Box } from '../../common/Grid'
import IsDesktop from '../../common/IsDesktop'
import IsMobile from '../../common/IsMobile'

import {
  LinkBlock
} from '..'

const ChineseNYHeader = css`
  color: #ca0a0a;
  font-weight: 500;
`

const HeaderStyle = props => css`
  ${tw`font-montserrat text-border-blue font-light text-4xl m-0 mb-6 ml-20`}
  p {
    margin: 0px;
  }

  @media screen and (max-width: 64em) {
    ${tw`ml-0`}
  }

    ${props.modification.includes('cny2021') ? ChineseNYHeader : null}
`

const Header = styled.h2`
  ${HeaderStyle}
`

const ChineseTextStyle = css`
  font-weight: 500;
`

const TextStyle = props => css`
  ${tw`font-raleway text-lg text-blog-text leading-loose`}

  p {
    margin: 0;
  }

  a {
    ${tw`text-border-blue no-underline focus:underline hover:underline`}
  }

  ${props.modification && props.modification.includes('cny2021') ? ChineseTextStyle : null}
  margin-right: ${props.modification && props.modification.includes('extrapadding') ? '2rem' : '0'};

  @media screen and (max-width: 64em) {
    margin-right: 0;
  }
`

const Text = styled.div`
  ${TextStyle}
  ${tw`mb-6`}
`

const SubText = styled.div`
  ${TextStyle}
  ${tw`mt-6`}
`

const MiniImage = styled.img`
  ${tw`mr-4`}
`

const MobileMiniImage = styled.img`
  margin-left: ${props => props.modification === 'left' || props.modification.includes('left') ? '0px' : 'auto'};
  margin-right: ${props => props.modification === 'right' || props.modification.includes('right') ? '0px' : 'auto'};
  max-width: 150px;
  margin-bottom: 20px;
`

const MobileProductLandingWrapper = styled.div`
  margin: 0 -1.25rem;
  max-width: 500px;
  margin: 0 auto;
`

const MobileProductImg = styled.img`
  width: calc(100% - 1.25rem);
  margin-bottom: -70px;
  margin-left: ${props => props.modification === 'left' || props.modification.includes('left') ? 'auto' : '0px'};
  margin-right: ${props => props.modification === 'right' || props.modification.includes('right') ? 'auto' : '0px'};
`

const MobileProductLandingContainer = styled.div`
  ${tw`ml-5 mr-5 mb-20 mt-0`}
`

const ProductLandingBlock = (props) => {
  const { modification, title, image, miniImage, description, subDescription, buttonName, buttonLink } = props;

  const eventName = buttonLink.split('/').pop().split('-')[0]

  return (
    <React.Fragment>
      <IsDesktop>
        <Flex
          alignItems="center"
          data-imageboss-bg-src={image}
          data-imageboss-operation="height"
          data-imageboss-height="460"
          css={
            css`
              background-color:#fff;
              background-position:${(modification === 'right' || modification.includes('right') ? 'left' : 'right')};
              background-repeat: no-repeat;
              background-size: contain;
              height:460px;
              position: relative;
              margin-bottom:20px;
            `
          }
        >
          {modification === 'right' || modification.includes('right') ? <Box width={1 / 2} px={2} /> : null}
          <Box width={1 / 2} px={2} css={css`position:relative;`}>
            <Header dangerouslySetInnerHTML={{ __html: title }} modification={modification} />
            <Flex>
              <Box width={2/6} mr={4}>
                <MiniImage src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-imageboss-src={miniImage} alt='' data-imageboss-operation="width" data-imageboss-width="190" />
              </Box>
              <Box width={4/6}>
                <Text dangerouslySetInnerHTML={{ __html: description }} modification={modification} />
                <LinkBlock
                  modification={modification}
                  title={buttonName}
                  link={buttonLink}
                  ga={{
                    category:'Engagement',
                    action:'Click',
                    label:`Product_Landing_${eventName}_Click_Through`
                  }}
                />
                {subDescription ? <SubText dangerouslySetInnerHTML={{ __html: subDescription }} /> : null}
              </Box>
            </Flex>
          </Box>
          {modification === 'left' || modification.includes('left') ? <Box width={1 / 2} px={2} /> : null}
        </Flex>
      </IsDesktop>
      <IsMobile>
        <MobileProductLandingWrapper>
          <MobileProductImg src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-imageboss-src={image} data-imageboss-width="400" alt={striptags(title)} modification={modification} loading="lazy" />
          <MobileProductLandingContainer>
            <MobileMiniImage src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt='' data-imageboss-src={miniImage} data-imageboss-operation="width" data-imageboss-width="190" modification={modification} loading="lazy" />
            <Header dangerouslySetInnerHTML={{ __html: title }} modification={modification} />
            <Text dangerouslySetInnerHTML={{ __html: description }} modification={modification} />
            <LinkBlock modification={modification} title={buttonName} link={buttonLink} />
            {subDescription ? <SubText dangerouslySetInnerHTML={{ __html: subDescription }} /> : null}
          </MobileProductLandingContainer>
        </MobileProductLandingWrapper>
      </IsMobile>
    </React.Fragment>
  )
}

ProductLandingBlock.propTypes = {
  modification: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.objectOf({
      base64: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string
    }),
    PropTypes.string
  ]).isRequired,
  title: PropTypes.string.isRequired,
  miniImage: PropTypes.oneOfType([
    PropTypes.objectOf({
      base64: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string
    }),
    PropTypes.string
  ]).isRequired,
  description: PropTypes.string.isRequired,
  subDescription: PropTypes.string,
  buttonName: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired
}

ProductLandingBlock.defaultProps = {
  modification: '',
  subDescription: null
}

export const productLandingBlockFragment = graphql`
  fragment productLandingBlockFragment on ProductLandingBlock {
    id
    modification
    image_url
    mini_image_url
    description
    title
    button_name
    button_link
  }
`

export default ProductLandingBlock
